<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">

          <!-- Left-aligned content -->
          <div class="d-inline-flex align-center">
            <v-switch
              v-model="activeOnly"
              data-cy="switch-active-only"
              :label="$lang.labels.activeOnly"
              class="pl-5 pr-3"
              dense
              inset
            ></v-switch>

            <v-text-field
              v-model="search"
              class="custom-search-perm"
              hide-details
              outlined
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
          </div>

          <!-- Right-aligned buttons -->
          <div class="d-inline-flex align-center ml-auto">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="$emit('fetchSettings', { options, type: selectedType, search: search && search.length > 1 ? search : '' })"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>

            <v-btn
              v-if="roles.includes('SETTING_CREATOR')"
              outlined
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'settingCreate', params: { type: $route.name.toLowerCase().replace('-', '_') } }"
            >
              <v-icon
                left
                dark
              >
                mdi-file-plus
              </v-icon>
              {{ $lang.actions.create }}
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex justify-space-between align-center">
          <!-- Name on the left with a fixed or max width -->
          <div class="text-start name-container clickable-simple"> <!-- Adjust the margin-right here -->
            <router-link v-if="item.name.length < 86" class="text-decoration-none" :to="{ name: 'settingEdit', params: { id: item.id } }">
              <span class="font-weight-regular color-primary">{{ item.name }}</span>
            </router-link>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <router-link
                    class="text-decoration-none"
                    :to="{ name: 'settingEdit', params: { id: item.id } }"
                  >
                    <span class="font-weight-regular color-primary">{{ item.name.substring(0, 62) }}...</span>
                  </router-link>
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
          <!-- 3 dots menu on the right -->
          <div>
            <v-menu class="custom-edit-menu" offset-y nudge-left="75" transition="slide-y-transition"> <!-- Adjust nudge-left if necessary -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  variant="text"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <!-- Menu with actions -->
              <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid;">
                <v-list dense>
                  <!-- Deactivate/Activate button -->
                  <v-list-item
                    :disabled="item.isSystem"
                    link
                    class="text--secondary font-weight-light"
                    @click="$emit('changeStatus', {id: item.id, status: item.status})"
                  >
                    {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.status.YES : $lang.status.NO }}</div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { debounce } from 'lodash'
export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.labels.name, value: 'name', sortable: true },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.labels.status, value: 'status', sortable: true }
        
      ],
      selectedType: '',
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchSettings', { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    },
    search: {
      handler: debounce( function (val) {
        if (val && val.length > 1 && !this.lock) this.$emit('fetchSettings', { options: this.options, search: val, resetPage: true })
        if (!this.lock && (val === null || val === '')) this.$emit('fetchSettings', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }, 500)
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchSettings', { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(4, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', class: 'created-on', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.settingList && preFill.settingList[this.$route.name]) {
        this.search = preFill.settingList[this.$route.name].search
        this.activeOnly = preFill.settingList[this.$route.name].activeOnly
        this.options = preFill.settingList[this.$route.name].options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
      } else {
        this.$emit('fetchSettings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      }
    } else {
      this.$emit('fetchSettings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.settingList) preFill.settingList = {
          frontend: {},
          instance: {},
          process: {}
        }

        if (!preFill.settingList[this.$route.name]) preFill.settingList[this.$route.name] = {
          search: '',
          activeOnly: true,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.settingList[this.$route.name].search = this.search
        preFill.settingList[this.$route.name].activeOnly = this.activeOnly
        preFill.settingList[this.$route.name].options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
